import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Box, Button } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark, prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ApexCharts from 'apexcharts';


const displayTS = (updatedAt) => {
    const isoDate = updatedAt
    const localDate = new Date(isoDate);
    const nowDate = Date.now();
    const diffTime = Math.abs(nowDate - localDate); // diffTime in milliseconds

    // console.log({ utcDate: utcDate, localDate: localDate, nowDate: nowDate, diffTime: diffTime })

    return { diffTime: diffTime, timeStr: localDate.toLocaleString() }
}

function DeviceDialog({ name, updatedAt, text, code, heatmaps, serial, model, critical, chassis }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDownload = () => {
        // Create a blob with the JSON content
        const blob = new Blob([text], { type: 'application/json' });

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${name}.json`; // Set the default filename for the download

        // Append the link to the body and trigger the click event to start the download
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up by revoking the object URL after the download
        URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
    };


    // console.log(updatedAt)
    const { diffTime, timeStr: ts } = displayTS(updatedAt)

    // let buttonStyle = "outlined"
    // if (diffTime <= 1000 * 60) {
    //     buttonStyle = "contained"
    // }

    let buttonStyle = "text"
    if (name !== 'TempStats' && name !== 'FreqStats' && name !== 'VoltageStats' && name !== undefined) {
        let capitalizedString = name.toUpperCase()
        return (
            <Box>
                <Button variant={buttonStyle} onClick={handleClickOpen} sx={{fontSize: 11,}}>
                    {ts}
                </Button>
                <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                    <DialogTitle>{capitalizedString + " @ " + ts}</DialogTitle>
                    <DialogContent>
                        <SyntaxHighlighter language="json" style={prism}>
                            {text}
                        </SyntaxHighlighter>
                    </DialogContent>
                    <DialogActions>
                    <div>
                        <Button onClick={handleDownload}>Download</Button>
                        <Button onClick={handleClose}>OK</Button>
                    </div>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    } else {
        let capitalizedString = ""
        if (name === 'TempStats') {
            capitalizedString = "Temperature Heat Map "
        } else if (name === 'FreqStats') {
            capitalizedString = "Frequency Spectrum "
        } else if (name === 'VoltageStats') {
            capitalizedString = "Voltage Distribution Map "
        }
        if (chassis !== undefined && chassis !== null) {
            capitalizedString = capitalizedString + " Chassis: " + chassis
        }
        if (model !== undefined && model !== null) {
            capitalizedString = capitalizedString + " Model: " + model
        }
        if (serial !== undefined && serial !== null) {
            capitalizedString = capitalizedString + " SerialNo: " + serial
        }
        return (
            <Box>
                <Button
                    variant={buttonStyle}
                    onClick={handleClickOpen}
                    sx={{
                        fontSize: 11,
                        color: critical ? '#FF6666' : ''
                    }}
                >
                    {ts}
                </Button>
                {/* <Button variant={buttonStyle} onClick={handleClickOpen} sx={{fontSize: 11}}>
                    {ts}
                </Button> */}
                <Dialog open={open} onClose={handleClose} maxWidth="false" fullWidth>
                    <DialogTitle>{capitalizedString + " @ " + ts}</DialogTitle>
                    <DialogContent>
                    <HeatMap chipTemps={heatmaps} model={model} name={name} />
                    </DialogContent>
                    <DialogActions>
                        <div>
                            <Button onClick={handleDownload} style={{ textTransform: 'none' }}>
                                Save<span style={{ textTransform: 'uppercase' }}>R</span>awJson
                            </Button>
                            <Button onClick={handleClose}>OK</Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }
}

function HeatMap({ chipTemps, model, name }) {
    let charts = [];
    const chartRefs = [React.useRef(null), React.useRef(null), React.useRef(null)]; // Refs for each heatmap chart
    React.useEffect(() => {
        const hashboardData = [];
        let rangeSize = 0;
        let rowLen = 0
        for (let i = 0; i < chipTemps.length; i++) {
            if (chipTemps[i].length > 0) {
                hashboardData.push({ name: `HashBoard${i + 1}`, chipTemperatures: chipTemps[i] });
                if (chipTemps[i][0].length && rangeSize === 0) {
                    rangeSize = chipTemps[i][0].length;
                }
                if (rowLen === 0) {
                    rowLen = chipTemps[i].length
                }
            }
        }
        console.log('numCols',rangeSize, ' rowLen', rowLen)
        const numCols = rangeSize;

        charts = hashboardData.map((hashboard, idx) => {
            // Sort the data in reverse order to ensure correct rendering
            const seriesData = [];
            for (let row = rowLen - 1; row >= 0; row--) {
                let rowData = hashboard.chipTemperatures[row];
                rowData = rowData.map(row => {
                    if (row.id === -1) {
                      return { ...row, value: -2000 };
                    }
                    return row;
                  });
                seriesData.push({
                    name: `Row ${row + 1}`, // Use row number as the name
                    data: rowData.map(temp => ({ x:'', y: temp.value, z: temp.id}))
                });
            }
            let height = 50
            let width = '65%'
            if (model.startsWith('AD')) {
                height = 80;
                width = '90%'
            }
            let colorScale = {}
            if (name === 'TempStats') {
                colorScale = {
                    ranges: [
                    { from: -2000, to: -1000.01, color: 'white', name: " "},
                    { from: -1000, to: -50.01, color: '#A9A9A9', name: "Chip Error" }, // dark gray
                    { from: -50, to: 24.99, color: '#00008B' }, // dark blue
                    { from: 25, to: 49.99, color: '#ADD8E6' }, // light blue
                    { from: 50, to: 74.99, color: '#32CD32' }, // lime green
                    { from: 75, to: 99.99, color: '#FF8C00'}, // orange
                    { from: 100, to: 300, color: '#FF0000' } // red
                  ]
                }
            } else if (name === 'FreqStats') {
                colorScale = {
                    ranges: [
                        { from: -2000, to: -1001, color: 'white' },
                        { from: -1000, to: 199.99, color: '#A9A9A9' }, //dark gray
                        { from: 200, to: 2000.99, color: '#32CD32' }, //lime green
                        { from: 2001, to: 3000, color: '#FF0000' } //red
                    ]
                }
            } else if (name === 'VoltageStats') {
                colorScale = {
                    ranges: [
                        { from: -2000, to: -1001, color: 'white' },
                        { from: -1000, to: 0.17, color: '#A9A9A9' }, //dark gray
                        { from: 0.18, to: 0.44, color: '#32CD32' }, //lime green
                        { from: 0.45, to: 1.00, color: '#FF0000'} //red
                    ]
                }
            }
            const options = {
                series: seriesData,
                chart: { height: height * numCols, type: 'heatmap', width: width },
                plotOptions: {
                    heatmap: {
                        radius: 10,
                        enableShades: false,
                        distributed: true, // Ensure data is displayed by default without requiring click
                        colorScale: colorScale,
                    }
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        const data = w.config.series[seriesIndex].data[dataPointIndex];
                        const chipID = data.z;
                        return `<div style="color: #8A2BE2; font-size: 20px; font-weight: bold;">Chip ID: ${chipID}</div>`;
                    }
                },
                dataLabels: { enabled: true, style: { colors: ['#fff'] } },
                xaxis: { type: 'category', categories: Array.from({ length: numCols }, (_, i) => `Col ${i + 1}`) },
                yaxis: { categories: Array.from({ length: chipTemps[0].length }, (_, i) => `Row ${i + 1}`) },
                title: { text: '' }
            };

            const chart = new ApexCharts(chartRefs[idx].current, options);
            chart.render();
            return chart;
        });

        return () => {
            charts.forEach(chart => chart.destroy());
        };
    }, []);

    return (
        <div>
            {chartRefs.map((ref, idx) => (
                <div key={idx}>
                    <br />
                    <h2 style={{ textAlign: 'left', color: '#333333' }}>{`Hash Board ${idx + 1}`}</h2>
                    <div ref={ref}></div>
                    <br />
                </div>
            ))}
        </div>
    );
}


export { displayTS, DeviceDialog, HeatMap }